import * as React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'
import SolutionsSwiper from 'components/SolutionsSwiper'
import Scheme from 'components/Scheme'
import CustomerSwiper from 'components/CustomerSwiper'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import MaterialCard from 'components/MaterialCard'
import * as styles from './index.module.less'

// 更多精彩
const moreData = [
  {
    title: '新基建背景下，证券行业数字化建设的五大趋势',
    href: 'https://www.sensorsdata.cn/school/library/a37ee7ba372ded6ec23543650a993de8',
    imgSrc: require('assets/images/solution/security/m_01.png').default,
    butttonText: '前往下载报告',
  },
  {
    title: '证券行业数字化转型，如何做好数据基础建设？',
    href: 'https://www.sensorsdata.cn/school/library/c14eb301da1b29ab67144630eba5fa61',
    imgSrc: require('assets/images/solution/security/m_02.png').default,
    butttonText: '前往学习课程',
  },
  {
    title: '券商财富管理数字化转型成功的六大关键驱动力',
    href: 'https://www.sensorsdata.cn/school/library/9b7fcf5238a79093828f48ead5799b09',
    imgSrc: require('assets/images/solution/security/m_03.png').default,
    butttonText: '前往下载报告',
  },
  {
    title: '精细化运营驱动客户全生命周期管理',
    href: 'https://www.sensorsdata.cn/school/library/564ae00b711cb5929de57682180fadb2',
    imgSrc: require('assets/images/solution/security/m_04.png').default,
    butttonText: '前往学习课程',
  },
]

const Ecommerce: React.FC = (props) => {
  return (
    <Layout {...props}>
      <Seo
        title="证券行业｜ 资讯和产品智能推荐，打通ID-mapping - 神策数据解决方案"
        description="神策分析证券行业解决方案，券商数据分析，是可以私有化部署的用户行为分析产品。全端数据，实时打通，用户分群，精益分析。旨在帮助证券行业利用数据，驱动线上拉新和线下服务配合，实现财富管理转型的重要战略。"
        keywords="证券数据分析,证券行业数据分析,证券用户行为分析,证券线下投顾管理,证券行业用户行为,证券数据运营,证券数据采集,证券用户分群,证券用户画像,证券融精准用户营销,证券个性化营销"
        saTitle="首页-产品（行业解决方案）- 证券"
      />
      <main className={styles.Retail}>
        <SolutionsBanner
          className="banner-wrap"
          title="证券行业解决方案"
          subTitle="打造证券客户全域旅程数据平台，为财富管理转型保驾护航"
          desc="以客户体验为中心，搭建全生命周期运营平台，整合全域数据形成有效洞察，以数据赋能经营单元，让业绩提升有迹可循。"
          imgUrl={
            <img
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/icon-banner.png"
              alt="banner"
              className="banner-icon"
            />
          }
          buttonGroup={
            <Button href="/doSecurity" target="_blank">
              体验行业 Demo
            </Button>
          }
        />
        <SectionContent className="customer-wrap" title="众多券商信赖的数字化业务合作伙伴">
          <p className="customer-desc">
            神策数据已服务近 50 家国内券商及美港股证券行业优秀企业，国内 TOP10 券商已服务 7 家。
          </p>
          <img
            className="customer-logo"
            src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/customer-new.png"
            alt=""
          />
          <img
            className="customer-logo-mb"
            src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/customer-mb-new.png"
            alt=""
          />
          <CustomerSwiper
            dataSource={[
              {
                username: '海通证券',
                logo: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/logo-haitong.png',
                logoAlt: '海通证券',
                desc: '神策数据帮助我们整合多平台数据，在已有的数据根基平台和框架下，引进神策数据的私有化部署方案，完全符合证监会规定，也使海通证券在保证平台核心资产不外泄的情况下，整合多平台数据，打破业务数据和行为数据的隔离。',
              },
              {
                username: '中国银河证券',
                logo: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/logo-cgs.png',
                logoHeight: 28,
                logoHeightMb: 28,
                logoAlt: '中国银河证券',
                desc: '借助于神策数据的私有化部署方案，在保证合法合规和数据安全的前提下，我们在“中国银河证券”移动 App 端尝试并开启了数据采集、分析、发现问题、提出优化、做出更新、再反馈、再迭代的完整运营闭环。相信在与神策数据的不断交流合作中，我们在数据驱动运营探索的道路上会不断取得新的突破。',
              },
              // {
              //   username: '广发证券',
              //   logo: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/logo-guangfa.png',
              //   logoAlt: '广发证券',
              //   logoHeight: 33,
              //   logoHeightMb: 33,
              //   desc: '使用神策的私有化部署方案，满足了证券行业合规监管的要求。对数据团队，大大减轻了处理各类繁杂数据的 ETL 日常工作。对业务运营团队，更加快捷、方便地洞察业务指标的变化，不再依赖数据团队的开发进度，自助分析大大地拓展了业务分析的深度。对研发团队，有成熟便捷的、多种接入 SDK ，降低了日志埋点和上报的复杂度。 ',
              // },
            ]}
          ></CustomerSwiper>
          <div className="sd-button-group">
            <Button btnType="primary" href="/doSecurity" target="_blank">
              体验全场景证券行业 Demo
            </Button>
          </div>
        </SectionContent>
        <SectionContent
          className="solutions-wrap"
          title="更具互联网思维的财富管理数字化解决方案"
          backgroundColor="#F8FBFD"
        >
          <SolutionsSwiper
            className="solutions-swiper-container"
            dataSource={[
              {
                className: 'lifecycle',
                title: '全域全生命周期运营平台',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/s_01.png',
                imgWidth: 709,
                descList: [
                  {
                    title: '全域全端覆盖',
                    list: ['覆盖 App、微信生态、官网的全域全端运营能力及渠道价值分析'],
                  },
                  {
                    title: '全生命周期管理',
                    list: ['从潜客管理到客户赢回的全生命周期价值管理'],
                  },
                  {
                    title: '运营体系建设',
                    list: ['标杆券商服务经验沉淀，面向新用户、有效户和存量客户的精细化运营体系输出'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/doSecurity',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'report',
                title: '智能财富管理营销中台',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/s_02.png',
                imgWidth: 699,
                descList: [
                  {
                    title: '精益获客赋能',
                    list: [
                      '基于精准的用户画像，赋能渠道开拓与广告的精准投放，支撑线上与线下的立体式营销与实时结果回收',
                    ],
                  },
                  {
                    title: '全通道智能触达',
                    list: [
                      '先进的实时行为触发（Event-Triggered）营销自动化，一站式全通道触达，满足高中净值客户的个性化需求',
                    ],
                  },
                  {
                    title: '智能算法推荐',
                    list: ['智能算法与规则推荐双重方案，实现产品、功能与资讯的千人千面'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/doSecurity',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'database',
                title: '证券行业数据根基平台',
                imgUrl: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/s_03.png',
                imgWidth: 730,
                descList: [
                  {
                    title: '定制化采集方案',
                    list: ['证券行业定制化 ID-Mapping，全面打通业务数据与行为数据'],
                  },
                  {
                    title: '数据接入适配性佳',
                    list: ['行为数据接入无需额外开发，业务数据导入更高效，实施上线告别等待'],
                  },
                  {
                    title: '数据整合能力强',
                    list: ['入库强校验模式，保障全局数据准确性'],
                  },
                ],
                buttonList: [
                  {
                    text: '体验行业 Demo',
                    href: '/doSecurity',
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
            ]}
          />
        </SectionContent>
        <SectionContent className="framework-wrap" title="从数据根基到应用赋能的解决方案全景图">
          <div className="framework-content">
            <img
              src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/framework.png"
              className="framework-img"
              alt="以用户为中心的电商平台体验旅程"
            />
          </div>
          <div className="sd-button-group">
            <ScanCodeConsultButton
              buttonText="扫码咨询"
              qrcode={require('assets/images/solution/security/panorama_code.png').default}
              alt="扫码咨询"
            />
            <a className="linkBtn" target="_blank" href="https://www.sensorsdata.cn/democenter/securities.html">
              <span className="textBtn">场景解读</span>
            </a>
          </div>
        </SectionContent>
        <SectionContent className="scheme-wrap" title="四大独特优势护航券商数字化转型" backgroundColor="#F8FBFD">
          <Scheme
            columnNum={4}
            useSwiper={false}
            dataSource={[
              {
                title: '更懂行业',
                desc: '30+ 行业标杆客户服务案例，完善且合规的证券行业事件设计、指标体系与标签体系沉淀',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/t-01.png',
                alt: '更懂行业',
              },
              {
                title: '更懂客户',
                desc: '独立的第三方服务商，扎实的综合交付能力与陪伴式服务，近 100% 的私有化部署经验',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/t-02.png',
                alt: '更懂客户',
              },
              {
                title: '更懂用户',
                desc: '用户需求视角的运营与营销方法论输出，已在上百家 DAU 过百万的金融业及相关客户处落地应用',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/t-03.png',
                alt: '更懂用户',
              },
              {
                title: '更懂技术',
                desc: '数百人的产研团队，近十年研发迭代，产品成熟度高，性能稳定',
                icon: 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/solution/security/t-04.png',
                alt: '更懂技术',
              },
            ]}
          />
          <div className="sd-button-group">
            <Button btnType="primary" href="/doSecurity" target="_blank">
              体验行业 Demo
            </Button>
          </div>
        </SectionContent>
        <SectionContent title="行业洞察">
          <div className="more-brilliant-wrap">
            {moreData?.map((item) => (
              <MaterialCard
                key={item.title}
                title={item.title}
                href={item.href}
                imgSrc={item.imgSrc}
                buttonText={item.butttonText}
              />
            ))}
          </div>
        </SectionContent>
        <PageBottomCard
          title="更懂互联网玩法的券商财富管理解决方案！"
          desc="立即预约，神策证券事业部众多专家为您赋能！"
          leftButtonText="体验行业 Demo"
          leftButtonHref="/doSecurity"
          rightButtonText="预约上门演示"
          rightButtonHref="/form/parade.html"
        />
      </main>
    </Layout>
  )
}

export default Ecommerce
